<template>
    <nav>
        <div class="container">
            <div class="nav-content">
                <div class="nav-links">
                    <a href="#" @click.prevent>{{ isEnglish ? 'Home' : '首页' }}</a>
                    <a href="#" @click.prevent>{{ isEnglish ? 'About' : '关于' }}</a>
                    <a href="#" @click.prevent>{{ isEnglish ? 'Blog' : '博客' }}</a>
                    <a href="#" @click.prevent>{{ isEnglish ? 'Contact' : '联系' }}</a>
                </div>
                <div class="controls">
                    <button @click="$emit('toggle-language')">
                        {{ isEnglish ? '中文' : 'English' }}
                    </button>
                    <button @click="$emit('toggle-theme')">
                        {{
                            isDarkTheme
                                ? isEnglish
                                    ? 'Light Mode'
                                    : '浅色模式'
                                : isEnglish
                                ? 'Dark Mode'
                                : '深色模式'
                        }}
                    </button>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'BlogNavigation',
    props: {
        isEnglish: Boolean,
        isDarkTheme: Boolean,
    },
    emits: ['toggle-language', 'toggle-theme'],
}
</script>

<style scoped>
nav {
    background: var(--nav-bg);
    padding: 10px 0;
}
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}
.nav-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.nav-links {
    display: flex;
    flex-wrap: wrap;
}
.nav-links a {
    color: white;
    text-decoration: none;
    padding: 5px 10px;
    margin: 2px;
}
.controls {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}
.controls button {
    background: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin: 2px;
}

@media (max-width: 600px) {
    .nav-content {
        flex-direction: column;
        align-items: stretch;
    }
    .nav-links,
    .controls {
        justify-content: center;
    }
}
</style>
