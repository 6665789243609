<template>
    <section class="post-grid">
        <PostCard v-for="post in posts" :key="post.id" :post="post" :isEnglish="isEnglish" />
    </section>
</template>

<script>
import PostCard from './PostCard.vue'

export default {
    name: 'PostGrid',
    components: {
        PostCard,
    },
    props: {
        isEnglish: Boolean,
    },
    data() {
        return {
            posts: [
                {
                    id: 1,
                    titleEn: 'Getting Started with React Hooks',
                    titleZh: 'React Hooks入门',
                    contentEn:
                        'Learn how to use React Hooks to simplify your components and manage state effectively.',
                    contentZh: '学习如何使用React Hooks来简化你的组件并有效管理状态。',
                },
                {
                    id: 2,
                    titleEn: "The Future of CSS: What's Coming in 2024",
                    titleZh: 'CSS的未来：2024年的新特性',
                    contentEn:
                        'Explore the exciting new features and improvements coming to CSS this year.',
                    contentZh: '探索今年CSS即将推出的令人兴奋的新特性和改进。',
                },
                {
                    id: 3,
                    titleEn: 'Building Scalable APIs with GraphQL',
                    titleZh: '使用GraphQL构建可扩展的API',
                    contentEn:
                        'Discover how GraphQL can help you create more efficient and flexible APIs for your projects.',
                    contentZh: '了解GraphQL如何帮助你为项目创建更高效、更灵活的API。',
                },
            ],
        }
    },
}
</script>

<style scoped>
.post-grid {
    margin: 0 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
}
</style>
