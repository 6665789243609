<template>
    <section class="featured-post">
        <h2>{{ isEnglish ? 'Featured Post' : '特色文章' }}</h2>
        <h3>
            {{
                isEnglish
                    ? '10 Essential Web Development Tools for 2024'
                    : '2024年10个必备的Web开发工具'
            }}
        </h3>
        <p>
            {{
                isEnglish
                    ? 'Discover the must-have tools that are revolutionizing web development this year...'
                    : '探索今年正在改革Web开发的必备工具...'
            }}
        </p>
        <a href="#" @click.prevent>{{ isEnglish ? 'Read More' : '阅读更多' }}</a>
    </section>
</template>

<script>
export default {
    name: 'FeaturedPost',
    props: {
        isEnglish: Boolean,
    },
}
</script>

<style scoped>
.featured-post {
    background: var(--card-bg);
    padding: 20px;
    border-radius: 5px;
}
</style>
