<template>
    <header>
        <div class="container">
            <h1>{{ title }}</h1>
            <p>{{ subtitle }}</p>
        </div>
    </header>
</template>

<script>
import { computed } from 'vue'

export default {
    name: 'BlogHeader',
    props: {
        isEnglish: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const title = computed(() => (props.isEnglish ? `fc1904's Blog` : 'fc1904的博客'))
        const subtitle = computed(() =>
            props.isEnglish ? 'Exploring the world of technology and beyond' : '探索科技世界及其他'
        )

        return {
            title,
            subtitle,
        }
    },
}
</script>

<style scoped>
header {
    text-align: center;
    padding: 50px 0;
    background: var(--header-bg);
    color: white;
}

h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
}
</style>
