<template>
    <footer>
        <div class="container">
            <p>
                {{
                    isEnglish
                        ? '© 2024 Your Awesome Blog. All rights reserved.'
                        : '© 2024 fc1904 保留所有权利。'
                }}
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'BlogFooter',
    props: {
        isEnglish: Boolean,
    },
}
</script>

<style scoped>
footer {
    text-align: center;
    margin-top: 40px;
    padding: 20px;
    background: var(--nav-bg);
    color: white;
}
</style>
