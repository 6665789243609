<template>
    <div class="card post-card">
        <h3>{{ isEnglish ? post.titleEn : post.titleZh }}</h3>
        <p>{{ isEnglish ? post.contentEn : post.contentZh }}</p>
        <a href="#" @click.prevent>{{ isEnglish ? 'Read More' : '阅读更多' }}</a>
    </div>
</template>

<script>
export default {
    name: 'PostCard',
    props: {
        post: Object,
        isEnglish: Boolean,
    },
}
</script>

<style lang="less" scoped>
@import '../assets/variables.less';

.post-card {
    // 卡片的特定样式可以在这里添加
    padding: 20px;

    h3 {
        margin-bottom: 10px;
    }

    a {
        display: inline-block;
        margin-top: 10px;
        color: @primary-color;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
