<template>
    <div id="app">
        <div class="app-container">
            <Header :isEnglish="isEnglish" />
            <Navigation
                :isEnglish="isEnglish"
                :isDarkTheme="isDarkTheme"
                @toggle-language="toggleLanguage"
                @toggle-theme="toggleTheme"
            />
            <main class="main-content">
                <div class="container">
                    <FeaturedPost :isEnglish="isEnglish" />
                    <PostGrid :isEnglish="isEnglish" />
                    <CTASection :isEnglish="isEnglish" />
                </div>
            </main>
            <Footer :isEnglish="isEnglish" />
        </div>
    </div>
</template>

<script>
import { watch } from 'vue'
import { useLanguage } from './composables/useLanguage'
import { useTheme } from './composables/useTheme'

import Header from './components/Header.vue'
import Navigation from './components/Navigation.vue'
import FeaturedPost from './components/FeaturedPost.vue'
import PostGrid from './components/PostGrid.vue'
import CTASection from './components/CTASection.vue'
import Footer from './components/Footer.vue'

export default {
    name: 'App',
    components: {
        Header,
        Navigation,
        FeaturedPost,
        PostGrid,
        CTASection,
        Footer,
    },
    setup() {
        const { isEnglish, toggleLanguage } = useLanguage()
        const { isDarkTheme, toggleTheme } = useTheme()

        // 监听主题变化并更新 body 类
        watch(
            isDarkTheme,
            (newValue) => {
                if (newValue) {
                    document.body.classList.add('dark-theme')
                } else {
                    document.body.classList.remove('dark-theme')
                }
            },
            { immediate: true }
        ) // immediate: true 确保在挂载时立即运行一次

        return {
            isEnglish,
            isDarkTheme,
            toggleLanguage,
            toggleTheme,
        }
    },
}
</script>

<style lang="less">
@import './assets/reset.less';
@import './assets/variables.less';

@primary-color: #3498db;
@secondary-color: #2ecc71;

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;

    .header {
        background-color: @primary-color;
        color: white;

        h1 {
            font-size: 24px;
        }
    }

    .content {
        padding: 20px;

        a {
            color: @secondary-color;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

:root {
    /* 浅色主题 */
    --bg-color: #f5f5f5;
    --text-color: #333333;
    --header-bg: linear-gradient(135deg, #6e8efb, #a777e3);
    --card-bg: #ffffff;
    --nav-bg: #333;
    --scrollbar-thumb-color: rgba(0, 0, 0, 0.2);
    --scrollbar-track-color: rgba(0, 0, 0, 0.1);
}

.dark-theme {
    /* 改进的深色主题 */
    --bg-color: #1a1a1a;
    --text-color: #e0e0e0;
    --header-bg: linear-gradient(135deg, #34495e, #2c3e50);
    --card-bg: #2c2c2c;
    --nav-bg: #222;
    --scrollbar-thumb-color: rgba(255, 255, 255, 0.2);
    --scrollbar-track-color: rgba(255, 255, 255, 0.1);
}

body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    background-color: var(--bg-color);
    color: var(--text-color);
    transition: background-color 0.3s, color 0.3s;
    margin: 0;
    padding: 0;
}

.card,
.featured-post,
.post-card {
    background-color: var(--card-bg);
    color: var(--text-color);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dark-theme .card,
.dark-theme .featured-post,
.dark-theme .post-card {
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

// 在你的组件样式中使用这些变量
.card {
    margin: 0 6px;
    background-color: var(--card-bg);
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
    transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1);
    box-shadow: 0 4px 6px var(--card-shadow-color), 0 1px 3px var(--card-shadow-color);

    &:hover {
        transform: perspective(1000px) rotateX(2deg) rotateY(2deg) scale(1.02);
        box-shadow: 0 8px 12px var(--card-shadow-color), 0 3px 6px var(--card-shadow-color);
    }

    // 光亮主题
    :root & {
        --card-bg: @card-bg-light;
        --card-shadow-color: @card-shadow-light;
    }

    // 深色主题
    .dark-theme & {
        --card-bg: @card-bg-dark;
        --card-shadow-color: @card-shadow-dark;
    }
}

// 对于 featured post 或其他特殊卡片，你可以添加额外的效果
.featured-post {
    .card(); // 继承基本卡片样式
    margin: 20px 80px;
    transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1);

    &:hover {
        transform: perspective(1000px) rotateX(3deg) rotateY(3deg) scale(1.03);
        box-shadow: 0 12px 24px var(--card-shadow-color), 0 4px 8px var(--card-shadow-color);
    }
}

a {
    color: #3498db;
}

.dark-theme a {
    color: #5dade2;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex: 1;
}

/* 针对WebKit浏览器（Chrome、Safari等） */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: red;
}

::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 5px !important;
}

::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
}

/* 针对Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}

/* 滚动条悬停效果 */
::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-color-hover, rgba(0, 0, 0, 0.3));
}

.dark-theme ::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-color-hover, rgba(255, 255, 255, 0.3));
}

/* 适应移动设备 */
@media (max-width: 768px) {
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
}
</style>
