<template>
    <section class="cta-section">
        <h2>{{ isEnglish ? 'Never Miss a Post!' : '不要错过任何文章！' }}</h2>
        <p>
            {{
                isEnglish
                    ? 'Subscribe to our newsletter and stay up-to-date with the latest in tech.'
                    : '订阅我们的新闻邮件，随时了解最新科技动态。'
            }}
        </p>
        <a href="#" @click.prevent class="cta-button">{{
            isEnglish ? 'Subscribe Now' : '立即订阅'
        }}</a>
    </section>
</template>

<script>
export default {
    name: 'CTASection',
    props: {
        isEnglish: Boolean,
    },
}
</script>

<style scoped>
.cta-section {
    text-align: center;
    margin: 40px 0;
    padding: 20px;
    background: var(--card-bg);
    border-radius: 5px;
}
.cta-button {
    display: inline-block;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}
</style>
