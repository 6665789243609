import { ref } from 'vue'

export function useTheme() {
    const isDarkTheme = ref(false)

    const toggleTheme = () => {
        isDarkTheme.value = !isDarkTheme.value
    }

    return {
        isDarkTheme,
        toggleTheme,
    }
}
