import { ref } from 'vue'

export function useLanguage() {
    const isEnglish = ref(false)

    const toggleLanguage = () => {
        isEnglish.value = !isEnglish.value
    }

    return {
        isEnglish,
        toggleLanguage,
    }
}
